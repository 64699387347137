:root {
  --orange: #e86c57;
  --pink: #fc7272;
  --blue: #262b40;
  --yellow: #ffbc00;
  --white: #ffffff;
  --light-grey: #a3a3a3;
  --grey: #787878;
  --black: #000000;
}

.btn-border {
  border-radius: 30px;
}
.border-left {
  border-left: 2px solid #d9d9d9;
  margin-right: 6px;
}

.light-text {
  font-weight: 200;
}

.orange-background {
  background-color: var(--orange);
}

.pink-background {
  background-color: var(--pink);
}

.yellow-text {
  color: var(--yellow);
}

.orange-text {
  color: var(--orange);
}

.white-background {
  background-color: var(--white);
}

.white {
  color: var(--white);
}

.blue-background {
  background-color: var(--blue) !important;
}

.blue-text {
  color: var(--blue);
}

.grey-text {
  color: var(--grey);
}
.pointer {
  cursor: pointer !important;
}

.bold-text {
  font-weight: 600;
}
.btn-blue {
  background: #0070c1;
  color: #fff;
}
.btn-white {
  background: #ffffff;
  border: 1px solid #a3a3a3;
  color: #000;
}
.btn:hover {
  background: #212529;
  color: #fff;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
