:root{
    --header-height: 3rem;
    --nav-width: 68px;
    --first-color: #4723D9;
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --normal-font-size: 1rem;
    --z-fixed: 100;
    --web-color:#262b40;
}
*,::before,::after{
    box-sizing: border-box
}
.body-wrap{
    position: relative;
    font-size: var(--normal-font-size);
    transition: .5s
}
a{
    text-decoration: none
}

.input_4{
    border:none;
    border-bottom:1px solid #ffffff;
    width:100%;
    background: none;
    color:#fff;
    margin-top:5px;
}

.input_4:focus-visible {
    outline:none;
    transition:0.3s;
    border-bottom:1px solid #8b95bc; 
  }

.nav-link { font-size: 14px;}
.header{
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    background:var(--web-color);
    left: 0;
    align-items: center;
    padding: 3px 3rem;
    z-index: var(--z-fixed);
    transition: .5s
}
.header_toggle{
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
    /* position: absolute; */
}
.header_img{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}
.header_img img{
    width: 40px;
}
.l-navbar{
    position: fixed;
    top: -1px;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    padding: .5rem 1rem 0 0;
    transition: .5s;
    margin-top: 65px;
    background-image: linear-gradient(to bottom, rgba(38, 43, 64, 0.908), rgba(38, 43, 64, 0.623)), url('../../Static/Img/Login&Signup/dashboard_img.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.top-bottom-bar-btn :hover{
    background-color: #FC7272;
}
.menu_active {
    background-color: #FC7272 ;
}
.nav{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}
.top-bar-btn :hover{
    border-style: none;
    text-decoration: none;
    color: #0084E4;
}
.top-bar-btn{
    border-style: none;
    background:none;
    text-decoration: none;
    margin: 2px;
    color: #0084E4;
}
.nav_logo, .nav_link{
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    border: none;
    /* background-color: #ffffff; */
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem
}
.nav_logo{
    margin-bottom: 2rem
}
.nav_logo-icon{
    font-size: 1.25rem;
    color:#000000
}
.nav_logo-name{
    color: #000000;
    font-weight: 700
}
.nav_link{
    position: relative;
    color: #000000;
    margin-bottom: 1.5rem;
    transition: .3s
}
.nav_link:hover{
    box-shadow: 0px 0px 2px lightgray;
    background: #444a62;
    border-radius: 7px;
    cursor: pointer;
}

.nav_icon{
    font-size: 1.25rem
}
.background-light-grey {
    background-color: #F5F5F5;
}


.sidebar-img-wrap img{
   display: block;
   margin: auto;
   width: 75px;
}
.sidebar-img {
    width: 40px;
    margin-left: 4px;
}

.hidden-p {
    display: none;
}

.show2{
    left: 0;
}
.body-pd{
    padding-left: calc(var(--nav-width) + 0px);
  
}

.header_img button{
    background:#ff0000 !important;
}

.header_img button:active{
    color:#ffffff !important;
    
}


.header_img button:hover{
    background:#d80303 !important;
}

.card{ 
    min-height: 116px;
    transition: 0.3s;
    border: 1px solid #262b40;
}

.card:hover{ 
    transition: 0.3s;
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px -12px rgba(0, 0, 0, 0.25);
}

.btn-danger:hover{
    background:#d80303 !important
}

.active{
    box-shadow: 0px 0px 2px rgb(156, 156, 156);
    background: #444a627f;
    border-radius: 2px;
    cursor: pointer;
}

table th{
    background:var(--web-color) !important;
    color:#fff !important;
}

.active::before{
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
}
.height-100{
    height:100vh
}

.form_add{
    border:1px solid;
     border-color:lightgray; 
      background:#ffffff;
      border-radius:5px;
      transition: 0.3s;
}

.form_add:hover{
    transition: 0.3s;
    transform: translateY(-3px);
    box-shadow: 0px 10px 20px -12px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px){
    .body-wrap{
     
        padding-left: calc(var(--nav-width) )
    }
    .header{
        height: calc(var(--header-height) + 1rem);
    }
    .header_img{
        width: 40px;
        height: 40px
    }
    .header_img img{
        width: 45px
    }


    .l-navbar{
 
        padding: 1rem 1rem 0 0
    }
    .show2{
        width: calc(var(--nav-width) + 156px)
    }
    .body-pd{
        padding-left: calc(var(--nav-width) + 96px)
    }
}

@media screen and (max-width: 991px){
    .header{
        padding: 0px 1rem;
    }
}
@media screen and (max-width: 767px){
    .sidebar-img{
     margin-left: -27px !important;
    }
    
    .l-navbar{
            top:-18px;
    }
}

