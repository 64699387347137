body{
    height:100%;
    background:#fff;
}


.pull-right{
    float:right;
}


.pull-right a{
    color:#ff0000;
}


.font_weight{
    font-weight:bold;
}


.head_top{
    margin-top:60px;
}

.left_sec{
margin-top:100px;
z-index: 1;
position: relative;
}

.left_sec a{
    color:#fff;
}

.border_form{
background:#fff;
border:1px solid #ced4da;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
}

.input_3{
    border:none;
    border-bottom:1px solid #262b40;
    width:100%;
}

.btn-2{
  background: #262b40;
  padding:10px; 
  color: #fff;
  border: none;
  transition:0.3s;
  border-radius:3px;
}

.btn-2:active{
  background: #262b40 !important;
  color: #fff !important;
}

.btn-1{
    width:100%;
    background: #262b40;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  border: none;
  transition:0.3s;
}

.btn-1:hover , .btn-2:hover{
    transition:0.3s;
    background:#353c59;
}

.input_3:focus-visible {
    outline:none;
    transition:0.3s;
    border-bottom:1px solid #8b95bc; 
  }

  .left_side{
    justify-content : center;
    align-items : center;
    background-image: linear-gradient(to bottom, rgba(38, 43, 64, 0.908), rgba(38, 43, 64, 0.623)), url('../../Static/Img/Login&Signup/dashboard_img.webp');
    background-size: cover;
    background-repeat:no-repeat;
    height : 100vh;
    color : #FFF;
    padding:68px;
    background-position: center;
  }
  .right_side{
    justify-content : center;
    align-items : center;
    background-size: cover;
    background-repeat: no-repeat;
    height : 100vh;
    color : #3d231b;
    box-shadow: -10px 0px 30px #00000057;
    padding:100px;
  }
  @media (max-width: 991px) { 
    .left_side{
      display:none;
    }
    .right_side{
      height:100vh;
    }

    .form-top{
        background-image:url('../../Static/Img/Login&Signup/signin.svg');
        background-repeat: no-repeat;
        background-position: -25px -5px;
      }
    
      .form_login{
        background:#fff;
        border:1px solid gray;
      padding: 25px;
        margin-top:15px;
        border-radius: 8px;
      }

      .head_top {
        margin-top:0px;
      }

  }

  @media (max-width:450px) { 

    .right_side{
        padding:40px;
    }
  }


  .circles-container{
	width: 50%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
}

.circle{
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background-color: rgba(255,255,255,0.5);
	animation-name: float;
	animation-duration: 25s;
	animation-direction: alternate;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	bottom: -150px;
}

.circle:first-child{
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0;
}

.circle:nth-child(2){
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 12s;
	animation-duration: 10s;
}

.circle:nth-child(3){
	left: 70%;
	width: 40px;
	height: 40px;
	animation-delay: 4s;
}

.circle:nth-child(4){
	left: 5%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 20s;
}

.circle:nth-child(5){
	left: 0%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 10s;
}

.circle:nth-child(6){
	left: 45%;
	width: 70px;
	height: 70px;
	animation-delay: 10s;
}

.circle:nth-child(7){
	left: 35%;
	width: 50px;
	height: 50px;
	animation-delay: 7s;
	animation-duration: 9s;
}

.circle:nth-child(8){
	left: 65%;
	width: 25px;
	height: 25px;
	animation-delay: 7s;
}

.circle:nth-child(9){
	left: 75%;
	width: 80px;
	height: 80px;
	animation-delay: 2s;
}

.circle:nth-child(10){
	left: 90%;
	width: 30px;
	height: 30px;
	animation-delay: 0s;
	animation-duration: 11s;
}


@keyframes float{
	0%{
		transform: translateY(0) rotate(0deg);
		opacity: 0.8;
		border-radius: 0;
	}

	100%{
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

.Cradit{
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.Cradit a{
color:#262b40;
}

.Cradit a:hover{
  color:#262b40;
  text-decoration: underline;
  }